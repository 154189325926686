import React, { createContext, useContext, useRef } from "react"

type LayoutContextValue = {
  focusMain: () => void
  setMainRef: (node: HTMLDivElement) => void
}

const LayoutContext = createContext<LayoutContextValue>({
  focusMain: () => {},
  setMainRef: () => {},
})

export const useLayoutContext = () => useContext(LayoutContext)

export const LayoutProvider: React.FC = ({ children }) => {
  const mainRef = useRef<HTMLDivElement | null>(null)

  const value: LayoutContextValue = {
    focusMain: () => mainRef.current?.focus(),
    setMainRef: (node) => (mainRef.current = node),
  }

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}
