import { useFormikContext } from "formik"
import { RefObject, useEffect, useMemo, useRef } from "react"

const UseFocusFirstInvalidField = ({
  fieldNameInputRefMap,
}: {
  fieldNameInputRefMap: {
    [fieldName: string]: RefObject<
      HTMLInputElement | HTMLButtonElement | HTMLSelectElement
    >
  }
}) => {
  const { submitCount, errors } = useFormikContext()

  const _firstInvalidField = Object.keys(errors)[0]
  const firstInvalidField = useMemo(() => _firstInvalidField, [
    _firstInvalidField,
  ])

  const lastSubmitCount = useRef(0)

  useEffect(() => {
    if (lastSubmitCount.current === submitCount || !firstInvalidField) return
    lastSubmitCount.current = submitCount

    // TODO: remove setTimeout
    setTimeout(() => fieldNameInputRefMap[firstInvalidField].current?.focus())
  }, [submitCount, firstInvalidField, fieldNameInputRefMap])

  return null
}

export default UseFocusFirstInvalidField
