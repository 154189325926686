import { FormHelperText } from "@material-ui/core"
import { Field, useFormikContext } from "formik"
import { CheckboxWithLabel } from "formik-material-ui"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 0.5em;
`
const StyledCheckboxWithLabel = styled(CheckboxWithLabel)`
  + .MuiFormControlLabel-label {
    font-size: 0.875rem;
    line-height: 1.2;
    color: ${(p) => p.theme.palette.text.secondary};
  }
`

const StyledError = styled(FormHelperText)`
  position: absolute;
  bottom: -0.5em;
  right: 0;
`

type FormikCheckboxFieldProps = {
  name: string
  label: React.ReactNode
  required?: boolean
  inputRef?: React.RefObject<HTMLButtonElement>
}

const FormikCheckboxField = ({
  name,
  label,
  required,
  inputRef,
}: FormikCheckboxFieldProps) => {
  const { errors, touched } = useFormikContext<{ [field: string]: boolean }>()

  return (
    <StyledContainer>
      <Field
        component={StyledCheckboxWithLabel}
        type="checkbox"
        color="primary"
        required={required}
        name={name}
        Label={{ label, style: { marginLeft: 0 } }}
        inputRef={inputRef}
        inputProps={{
          "aria-describedby": `${name}-helper-text`,
        }}
      />
      {errors[name] && touched[name] && (
        <StyledError id={`${name}-helper-text`} error>
          {errors[name]}
        </StyledError>
      )}
    </StyledContainer>
  )
}

export default FormikCheckboxField
