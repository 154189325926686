import { Link } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${(p) => p.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-top: auto;
  flex-shrink: 0;
`
const StyledMINTLogoImage = styled.img`
  width: 89px;
  height: 26px;
`

const Footer = () => (
  <StyledFooter>
    <Link
      href="http://mintapp.co.il/"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="מעבר לאתר מינט"
    >
      <StyledMINTLogoImage
        src="/created_by_mint.png"
        srcSet="/created_by_mint.png 1x, /created_by_mint@2x.png 2x, /created_by_mint@3x.png 3x"
        alt="Created by Mint"
      />
    </Link>
  </StyledFooter>
)

export default Footer
