import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
} from "@material-ui/core"
import { Menu as MenuIcon } from "@material-ui/icons"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import { logout, setOpen } from "features/auth/authSlice"
import NextLink from "next/link"
import React from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { toggleDrawer } from "./layoutSlice"

export const MOBILE_HEIGHT = 68

const StyledAppBar = styled(AppBar)`
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: ${MOBILE_HEIGHT}px;
    z-index: ${(p) => p.theme.zIndex.modal};
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    height: 80px;
  }
`
const StyledToolbar = styled(Toolbar)`
  flex-grow: 1;
`
const StyledContainer = styled(Container)`
  display: flex;
`
const StyledHeaderSide = styled.div<{ justify: "start" | "end" }>`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(p) => `flex-${p.justify}`};
`
const StyledMenuButton = styled(IconButton)`
  :focus {
    border: 1px solid #fff;
  }
`
const StyledLogo = styled.img`
  display: block;
  height: 47px;

  ${(p) => p.theme.breakpoints.up("md")} {
    height: 55px;
  }
`

const Header = () => {
  const { authenticated } = useTypedSelector((state) => state.auth)
  const { drawerOpen } = useTypedSelector((state) => state.layout)

  const dispatch = useAppDispatch()

  const isDesktop = useIsDesktop()

  const whatsAppLogo = <img src="/whatsapp.svg" alt="לוגו WhatsApp" />

  return (
    <StyledAppBar position={isDesktop ? "static" : "relative"}>
      <StyledToolbar disableGutters>
        <StyledContainer>
          {/* Right */}
          <StyledHeaderSide justify="start">
            <StyledMenuButton
              color="secondary"
              edge="start"
              onClick={() => dispatch(toggleDrawer())}
              aria-label="תפריט ראשי"
              aria-expanded={drawerOpen}
            >
              <MenuIcon />
            </StyledMenuButton>

            <Mobile>
              <IconButton
                aria-label="וואטסאפ"
                color="secondary"
                href="https://wa.me/9720532397240"
                target="_blank"
                rel="noopener noreferrer"
              >
                {whatsAppLogo}
              </IconButton>
            </Mobile>
          </StyledHeaderSide>

          {/* Center */}
          <NextLink href="/">
            <a aria-label="מאזדה, קישור לעמוד דגמים">
              <StyledLogo src="/logo.png" alt="" />
            </a>
          </NextLink>

          {/* Left */}
          <StyledHeaderSide justify="end">
            <Desktop>
              <Box mx={2} clone>
                <Button
                  startIcon={whatsAppLogo}
                  color="inherit"
                  href="https://wa.me/9720532397240"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  וואטסאפ
                </Button>
              </Box>
            </Desktop>

            <Button
              startIcon={<img src="/profile.svg" alt="" />}
              color="inherit"
              onClick={() =>
                authenticated ? dispatch(logout()) : dispatch(setOpen(true))
              }
            >
              {authenticated ? "התנתקות" : "התחברות"}
            </Button>
          </StyledHeaderSide>
        </StyledContainer>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Header
