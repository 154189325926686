import { FormControl, FormHelperText, InputLabel } from "@material-ui/core"
import { Field, useFormikContext } from "formik"
import { Select } from "formik-material-ui"
import React from "react"
import { useTypedSelector } from "store"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding-bottom: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(0.5)}px;
`
const StyledFormControl = styled(FormControl)`
  margin-bottom: 0;
`
const StyledError = styled(FormHelperText).attrs({ error: true })`
  position: absolute;
`

const ShowRoomSelect = ({
  inputRef,
}: {
  inputRef?: React.RefObject<HTMLSelectElement>
}) => {
  const { showRooms } = useTypedSelector((state) => state.showRooms)

  const { errors, touched } = useFormikContext<{ showRoom: number }>()

  return (
    <StyledContainer>
      <StyledFormControl>
        <InputLabel htmlFor="showRoom" required>
          אולם תצוגה
        </InputLabel>

        <Field
          component={Select}
          name="showRoom"
          id="showRoom"
          native
          inputRef={inputRef}
          aria-describedby="showRoom-helper-text"
        >
          <option value={0} disabled hidden></option>
          {showRooms.map((showRoom) => (
            <option key={showRoom.id} value={showRoom.id}>
              {showRoom.name}
            </option>
          ))}
        </Field>
      </StyledFormControl>

      {errors.showRoom && touched.showRoom && (
        <StyledError id="showRoom-helper-text">{errors.showRoom}</StyledError>
      )}
    </StyledContainer>
  )
}

export default ShowRoomSelect
