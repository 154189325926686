import { useRouter } from "next/router"
import React, { useCallback } from "react"
import { useTypedSelector } from "store"
import styled, { createGlobalStyle } from "styled-components"
import Drawer from "./Drawer"
import Footer from "./Footer"
import Header from "./Header"
import { LayoutProvider, useLayoutContext } from "./LayoutContext"

const GlobalStyle = createGlobalStyle`
  :root,
  body,
  #__next {
    height: 100%;
  }

  #__next {
    display: flex;
    flex-direction: column;
  }
`
const SkipMainLink = styled.a`
  top: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  :focus,
  :active {
    color: #fff;
    background-color: #000;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid ${(p) => p.theme.colors.red};
    text-align: center;
    font-size: 1.2em;
    z-index: 1200;
  }
`
export const StyledMain = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Layout: React.FC = ({ children }) => {
  const { pathname } = useRouter()

  const { focusMain, setMainRef } = useLayoutContext()

  const mainRef = useCallback((node: HTMLDivElement) => setMainRef(node), [
    setMainRef,
  ])

  const { selectedDetailingCode } = useTypedSelector((state) => state.detailing)
  const { selectedColorCode } = useTypedSelector((state) => state.color)

  return (
    <>
      <GlobalStyle />

      <SkipMainLink
        href=""
        onClick={(event) => {
          event.preventDefault()

          focusMain()
        }}
      >
        דלג לתוכן המרכזי
      </SkipMainLink>

      <Header />

      <Drawer />

      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
        {children}
      </StyledMain>

      {/* Show the footer in all pages except the wizard/model page(s) */}
      {pathname !== "/[model]" && <Footer />}

      {/* For Facebook marketing: */}
      <div
        id="product-id"
        data-id={`${selectedDetailingCode}_${selectedColorCode}`}
        style={{ display: "none" }}
      ></div>
    </>
  )
}

const _Layout: React.FC = (props) => (
  <LayoutProvider>
    <Layout {...props} />
  </LayoutProvider>
)

export default _Layout
